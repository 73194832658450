import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { BookingPage } from "./views/booking";
import { BookingFields, serviceFields } from "./components/options";
import { ConfirmBooking } from "./views/confirmBooking";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route
          path="/"
          element={
            <BookingPage serviceType={"booking"} fields={BookingFields} />
          }
        /> */}
        <Route
          path="/en/service-booking"
          element={
            <BookingPage
              languageType={"EN"}
              serviceType={"booking"}
              fields={BookingFields}
            />
          }
        />
        <Route
          path="/ar/service-booking"
          element={
            <BookingPage
              languageType={"AR"}
              serviceType={"booking"}
              fields={BookingFields}
            />
          }
        />
        <Route path="*" element={<Navigate to="/en/service-booking" />} />
        <Route
          path="/service-booking/:id/confirmation-details"
          // languageType={"EN"}
          element={
            <BookingPage serviceType={"service"} fields={serviceFields} />
          }
        />
        <Route
          path="/en/service-booking/:id/success"
          element={
            <ConfirmBooking
              languageType={"EN"}
              serviceType={"service"}
              fields={serviceFields}
            />
          }
        />
        <Route
          path="/ar/service-booking/:id/success"
          element={
            <ConfirmBooking
              languageType={"AR"}
              serviceType={"service"}
              fields={serviceFields}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
